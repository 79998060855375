<template>
  <div class="container">
    <div class="header"><span class="line"></span> 订单数据</div>
    <div class="nav">
    </div>
    <div class="search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="24">
          <a-col :lg="5" :md="8" :sm="12">
            <a-form-item label="订单号" :labelCol="{lg: {span: 8}, sm: {span: 7}}" :wrapperCol="{lg: {span: 16}, sm: {span: 17} }">
              <a-input v-model="queryParam.id" placeholder="请输入订单号"/>
            </a-form-item>
          </a-col>
          <a-col :lg="5" :md="8" :sm="12">
            <a-form-item label="门店名称" :labelCol="{lg: {span: 8}, sm: {span: 7}}" :wrapperCol="{lg: {span: 16}, sm: {span: 17} }">
              <a-input v-model="queryParam.fullName" placeholder="请输入门店名称"/>
            </a-form-item>
          </a-col>
          <!-- <a-col :lg="6" :md="8" :sm="12">
            <a-form-item label="支付日期" :labelCol="{lg: {span: 6}, sm: {span: 7}}" :wrapperCol="{lg: {span: 18}, sm: {span: 17} }">
              <a-range-picker style="width:230px" @change="onChange" v-model="date" />
            </a-form-item>
          </a-col> -->
          <a-col :lg="6" :md="8" :sm="12">
            <a-form-item label="支付状态">
              <a-select style="width: 150px" placeholder="请选择支付状态" v-model="queryParam.status">
                <a-select-option value="10">未支付</a-select-option>
                <a-select-option value="20">支付中</a-select-option>
                <a-select-option value="30">支付成功</a-select-option>
                <a-select-option value="40">支付失败</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col :lg="6" :md="8" :sm="12">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="handleToSearchEnterprise">查询</a-button>
              <a-button style="margin-left: 8px" icon="reload" @click="resetSearchEnterprise">重置</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <div>
      <s-table
        style="border-top:1px solid #F0F0F0;margin-top:16px"
        ref="table"
        :columns="raskColumns"
        :data="raskData"
        :rowKey="(record) => record.id"
      >
        <span slot="action" slot-scope="text, record">
          <a @click="detail(record)">查看详情</a>
        </span>
        <span slot="avator" v-if="record.headUrl" slot-scope="text, record">
          <img :src="record.headUrl" style="width:30px;height:30px" alt="">
        </span>
        <span slot="payType" slot-scope="text, record">
          <img v-if="record.payType==='30'" src="../../assets/zhifu_pay.png" style="width:30px;height:30px" alt="">
          <img v-if="record.payType==='20'" src="../../assets/weixin_pay.png" style="width:30px;height:30px" alt="">
        </span>
      </s-table>
    </div>
    <!-- 添加 -->
    <a-modal
      title="添加员工账户"
      :visible="visibleAdd"
      :confirm-loading="confirmLoadingAdd"
      @ok="handleOkAdd"
      @cancel="handleCancelAdd"
    >
      <a-form :form="form" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }" @submit="handleOkAdd">
        <a-form-item label="邮箱">
          <a-input
            placeholder="请填写邮箱"
            v-decorator="['email', { rules: [{ required: true, message: '请填写邮箱!' }] }]"
          />
        </a-form-item>
        <a-form-item label="姓名">
          <a-input
            placeholder="请填写姓名"
            v-decorator="['userName', { rules: [{ required: true, message: '请填写姓名!' }] }]"
          />
        </a-form-item>
        <a-form-item label="权限类型">
          <a-select
            v-decorator="[
              'roleType',
              { rules: [{ required: true, message: '请选择!' }] },
            ]"
            placeholder="请选择权限类型"
            @change="handleSelectChange"
          >
            <a-select-option value="102011">
              普通用户
            </a-select-option>
            <a-select-option value="102001">
              管理员
            </a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import STable from '@/components/table/';
import {
  getOrderList,
  addEnterpriseAccount,
  resetUserPwd,
  freezeEnterpriseAccount,
  unLock
} from '@/api/api';
export default {
  components: {
    STable
  },
  data () {
    return {
      raskColumns: [
        {
          title: '订单号',
          dataIndex: 'id',
          align: 'center',
          fixed: 'left'
        },
        {
          title: '门店名称',
          dataIndex: 'fullName',
          align: 'center',
        },
        {
          title: '订单金额',
          align: 'center',
          dataIndex: 'totalAmount',
          customRender: text => text.toFixed(2)
        },
        {
          title: '优惠金额',
          align: 'center',
          dataIndex: 'discountAmount',
          customRender: text => text.toFixed(2)
        },
        {
          title: '支付金额',
          align: 'center',
          dataIndex: 'payAmount',
          customRender: text => text.toFixed(2)
        },
        {
          title: '支付方式',
          dataIndex: 'payType',
          dataIndex: 'payType',
          scopedSlots: { customRender: 'payType' },
          align: 'center'
        },
        {
          title: '订单类型',
          align: 'center',
          dataIndex: 'orderType',
          customRender: text => text==='10'?'交易':'退款'
        },
        {
          title: '用户昵称',
          dataIndex: 'userName',
          align: 'center',
        },
        {
          title: '用户头像',
          dataIndex: 'avator',
          scopedSlots: { customRender: 'avator' },
          align: 'center',
        },
        {
          title: '创建时间',
          align: 'center',
          dataIndex: 'createTime',
        },
        {
          title: '状态',
          align: 'center',
          dataIndex: 'status',
          customRender: text => text==='10'?'未支付':text==='20'?'支付中':text==='30'?'支付成功':'支付失败'
        },
        {
          title: '支付时间',
          align: 'center',
          dataIndex: 'payTime',
        },
        // {
        //   title: '操作',
        //   align: 'center',
        //   dataIndex: 'action',
        //   scopedSlots: { customRender: 'action' },
        // }
      ],
      visibleAdd: false,
      confirmLoadingAdd:false,
      formLayout: 'horizontal',
      form: this.$form.createForm(this, { name: 'coordinated' }),
      queryParam: {
        fullName: '',
        status: undefined,
        startTime: undefined,
        endTime: undefined,
        id: undefined
      },
      date: []
    }
  },
  methods: {
    // 选择时间
    onChange(date, dateString) {
      this.queryParam.startTime = dateString[0]
      this.queryParam.endTime = dateString[1]
    },
    // 查看详情
    detail (record) {
      window.localStorage.setItem('orderInfo',JSON.stringify(record))
      window.localStorage.setItem('submenu','polyPayDetail')
      this.$router.push('/polyPayDetail')
    },
    // 获取列表
    raskData (parameter) {
      return getOrderList(Object.assign(this.queryParam,parameter)).then(res => {
        return res.result
      })
    },
    // 查询    
    handleToSearchEnterprise() {
      this.$refs.table.refresh(true)
    },
    // 重置
    resetSearchEnterprise() {
      this.queryParam = {
        fullName: '',
        status: undefined,
        startTime: undefined,
        endTime: undefined,
        id: undefined,
      };
      this.date = [];
      this.handleToSearchEnterprise();
    },
    // 添加
    handleOkAdd (e) {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          this.confirmLoadingAdd = true
          addEnterpriseAccount(values).then(res=> {
            this.confirmLoadingAdd = false
            if (res.success) {
              this.$message.success(res.message)
              this.$refs.raskTable.refresh(true)
              this.handleCancelAdd()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      });
    },
    handleCancelAdd () {
      this.form.resetFields()
      this.visibleAdd = false
    },
    // 冻结
    freeze (record) {
      let that = this
      this.$confirm({
        title: '确定冻结' + record.userName + '吗?',
        onOk() {
          return new Promise((resolve, reject) => {
            freezeEnterpriseAccount({id:record.id}).then(res => {
            if (res.success) {
              that.$message.success(res.message)
              that.$refs.raskTable.refresh(true)
              resolve()
            } else {
              that.$message.error(res.message)
              resolve()
            }
          })
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    },
    // 解冻
    refreeze (record) {
      let that = this
      this.$confirm({
        title: '确定解冻' + record.userName + '吗?',
        onOk() {
          return new Promise((resolve, reject) => {
            unLock({id:record.id}).then(res => {
              if (res.success) {
                that.$message.success(res.message)
                that.$refs.raskTable.refresh(true)
                resolve()
              } else {
                that.$message.error(res.message)
                resolve()
              }
            })
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    },
    // 重置密码
    reset (record) {
      let that = this
      this.$confirm({
        title: '确定重置' + record.userName + '的密码?',
        onOk() {
          return new Promise((resolve, reject) => {
            resetUserPwd({id:record.id}).then(res => {
              if (res.success) {
                that.$message.success(res.message)
                that.$refs.raskTable.refresh(true)
                resolve()
              } else {
                that.$message.error(res.message)
                resolve()
              }
            })
          }).catch(() => console.log('Oops errors!'));
        },
        onCancel() {},
      });
    },
  }
}
</script>
<style scoped lang="less">
.container{
  background: #FFFFFF;
  border-radius: 4px;
  height: 935px;
  padding: 40px;
}
.header{
  .line{
    width: 2px;
    height: 18px;
    background: #347CF5;
    margin-right: 9px;
  }
  color: #333333;
  font-size: 18px;
  display: flex;
  align-items: center;
}
.nav{
  margin: 10px 0 0 0;
  .common{
    color: #999999;
    margin: 0;
  }
}
.opt{
  margin: 20px 0 30px 0;
}
.search-wrapper{
  margin: 30px 0;
}
</style>